/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'gatsby';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import mainLogo from '../images/dtd/logo.png';


const IndexPageProd = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div className="py-12">
      <div className="container py-6">
        <section className="text-gray-700 body-font">
          <div className="container px-5 mx-auto">
            {/* <div className="flex flex-col w-full mb-12 text-center"> */}
              {/* <h1 className="py-4 text-center font-slim text-brown">
                Contact Us
            </h1> */}
              <p className="mx-auto text-base leading-relaxed lg:w-2/3 text-center">
            <img alt="drivers to drivers" className="inline w-48 m-auto" src={mainLogo} /><br /><br />
              Find your path on a driver's comunity. Earn, find, offer & solve your needs on track
              </p>
            {/* </div> */}
            <div className="mx-auto lg:w-1/2 md:w-2/3">
              {/* <div className="flex flex-wrap -m-2"> */}
                {/* <div className="w-1/2 p-2">
                  <input
                    className="w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary"
                    placeholder="Name"
                    type="text"
                  ></input>
                </div>
                <div className="w-1/2 p-2">
                  <input
                    className="w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary"
                    placeholder="Email"
                    type="email"
                  ></input>
                </div>
                <div className="w-full p-2">
                  <textarea
                    className="block w-full h-48 px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded resize-none focus:outline-none focus:border-secondary"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="w-full p-2">
                  <button className="flex px-8 py-2 mx-auto text-lg text-white border-0 rounded bg-orange hover:bg-orange-dark focus:outline-none">
                    Button
                  </button>
                </div> */}
                <div className="w-full p-2 pt-8 mt-8 text-center border-t border-gray-200">
                  <a href='mailto:' className="text-primary">info@driverstodrivers.com</a> 

                  {/* <p className="my-5 leading-normal">
                    49 Smith St.
                    <br />
                    Saint Cloud, MN 56301
                  </p> */}

                 
                </div>
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default IndexPageProd;
